body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  line-height: 1.6;
  background-color: #FFF8E7;
}

header {
  background-color: #D2B48C;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #FFF;
  margin: 0;
  font-size: 2rem;
}

.eyecatch {
  width: 100%;
  margin-bottom: 2rem;
}

.eyecatch img {
  width: 100%;
  height: auto;
}

.attention {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  position: relative;
  background: linear-gradient(to bottom, #F5DEB3, #FFF8E7);
}

.attention-logo {
  width: 100px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}

.attention-ribbon {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 204, 153, 0.8), rgba(255, 187, 119, 0.8));
  color: #000;
  text-align: center;
  padding: 1.5em 0;
  width: 100%;
}

.attention-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(245, 222, 179, 0.4), rgba(255, 248, 231, 0.1));
  z-index: -1;
}

.attention-content {
  text-align: center;
  margin-top: 50px;
}

.attention h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.attention-image-container {
  width: 50%;
  margin-top: 30px;
}

.attention-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  position: relative;
  background: linear-gradient(to bottom, #F5DEB3, #FFF8E7);
}

.hero-logo {
  width: 100px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}

.hero-ribbon {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 204, 153, 0.8), rgba(255, 187, 119, 0.8));
  color: #FFF;
  text-align: center;
  padding: 1.5em 0;
  width: 100%;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(245, 222, 179, 0.4), rgba(255, 248, 231, 0.1));
  z-index: -1;
}

.hero-content {
  text-align: center;
  margin-top: 50px;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.hero-image-container {
  width: 50%;
  margin-top: 30px;
}

.hero-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.features-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feature-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.feature-title {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.feature-description {
  font-size: 1rem;
}

.features-ribbon {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 204, 153, 0.8), rgba(255, 187, 119, 0.8));
  color: #000;
  text-align: center;
  padding: 1.5em 0;
  width: 100%;
  margin-bottom: 30px;
}

.features-content {
  position: relative;
  z-index: 1;
}

.features-content h2 {
  margin: 0;
  font-size: 2rem;
}

.products {
  padding: 50px;
  background-color: #F5DEB3;
  text-align: center;
}

.products h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.product {
  background-color: #FFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.product img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.product h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.product p {
  font-size: 1rem;
  color: #666;
}

.product-button {
  background-color: #A0522D;
  color: #FFF;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.product-button:hover {
  background-color: #8B4513;
}

.testimonials {
  padding: 50px;
  background-color: #FFF8E7;
  text-align: center;
}

.testimonials h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.testimonial {
  max-width: 600px;
  margin: 0 auto 30px;
  padding: 20px;
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.testimonial-text {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #666;
}

.user-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.cta {
  padding: 50px;
  background-color: #D2B48C;
  text-align: center;
}

.cta h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #000;
}

.cta-button {
  background-color: #8B4513;
  color: #FFF;
  border: none;
  padding: 20px 40px;
  font-size: 1.5rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: #A0522D;
  transform: scale(1.05);
}

footer {
  background-color: #8B4513;
  color: #FFF;
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
}

.box {
  width: 200px;
  height: 200px;
  background-color: #f5f5f5;
  border: 2px solid #333;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.ribbon {
  position: relative;
  background: #D2B48C;
  color: #fff;
  text-align: center;
  padding: 1em 2em; 
  overflow: hidden;
  width: max-content;
}

.ribbon::before, .ribbon::after {
  position: absolute;
  top: 0;
  content: '';
  display: block;
  border: 5em solid #A0522D;
  z-index: -1;
}

.ribbon::before {
  border-right-color: transparent;
  border-left-color: transparent;
  right: 0;
}

.ribbon::after {
  border-left-color: transparent;
  border-right-color: transparent;
  left: 0;
}

.image-container {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}